.wrap {
    width: 400px;
    max-height: 80vh;
    overflow-y: auto;
    // border: 1px solid red;
    color: white;
    //position: fixed;
    //right: 10vw;
    //top: 100px;
    margin: 0;
    padding: 0;

        
    .title{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;
        font-weight: 600;
    }
    
    .detail{
        width: 100%;
        // max-height: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 16px;
        // transition: max-height 0.3s ease-out;
        .dItem{
            display: flex;
            width: 100%;
            align-items: flex-start;
            justify-content: flex-start;
            margin: 2px 0;
            .label{
                text-align: left;
                color: rgba(255, 255, 255, .5);
                font-size: 14px;
            }
            .val{
                flex: 1;
                margin-left: 40px;
                text-align: left;
                color: #fff;
                font-size: 14px;
            }
        }
    }
        
}
.wrap::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    //background-color: #000;
    display: block;
}
  
.wrap::-webkit-scrollbar-thumb {
    //background: #000;
}

@primary-color: #1DA57A;