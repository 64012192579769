
.loginWrap{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  .policy{
      display: flex;
      justify-content: center;
  }
}
.btn{
  height: 59px;
  line-height: 59px;
  width: 100%;
  border-radius: 40px;
  background-color: var(--white);
  color: var(--btn-color-black);
  font-weight: bold;
}

.start{
  span{
      font-size: 26px;
      font-weight: bold;
  }
}
.logoWrap{
  width: 70%;
}

.wrapWholeBg{
  height: 100%;
  background-size: auto 100%;
  //padding-bottom: 20%;
  //padding-bottom: 10vh;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  //background-color: rgba(1,1,1,0.5);
}
@primary-color: #1DA57A;