.root {
    background: black;

    .lightpass{
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100vw;
        max-height: 100vh;
    }
  }

@primary-color: #1DA57A;