.spinAnimation(@lspin){
  @keyframes @lspin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
};

.spinAnimation(mySpin);

.animation(@animation-name,@animation-duration,@animation-timing-function,@animation-iteration-count){
  animation: @arguments;
}
.icon-wrapper {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .customIcon{
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    &.spln{
      .animation(mySpin, 1s, linear, 1);
    }
    &.spln2{
      .animation(mySpin, 1s, linear, infinite);
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
}
@primary-color: #1DA57A;