.root {
    height: 100%;
    background: black;
    position: relative;
    .tlTrigger{
        //position: absolute;
        height: 18000px;
        width: 100%;
        .tlTriggerScroll{
            height: 100%;
            width: 10px;
            position: absolute;
            left: 0;
            background-color: rgba(255,0,0,1);
            z-index: 1000;
        }
    }
    
    .video_background {
        position: fixed;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    .page3EndTextRoot{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        min-width: 40vw;
        aspect-ratio: 1.3333;
        //background-color: rgba(0,0,255,0.2);
        visibility: hidden;

        .page3EndTextTitle{
            position: absolute;
            left: 50%;
            top: 10%;
            white-space: nowrap;
            transform: translate(-50%, 0%);
        }
        .page3EndTextLeftRoot{
            position: absolute;
            left: 25%;
            top: 70%;
            width: 40%;
            transform: translate(-50%, 0%);
            text-align: left;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;

            .page3EndTextLeftTitle{
                width: 100%;
                text-align: left;
                line-height: 150%;
            }
            .page3EndTextLeft{
                width: 100%;
                text-align: left;
                line-height: 150%;
            }
        }
        .page3EndTextRightRoot{
            position: absolute;
            left: 75%;
            top: 70%;
            width: 40%;
            transform: translate(-50%, 0%);
            text-align: left;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;

            .page3EndTextRightTitle{
                width: 100%;
                text-align: left;
                line-height: 150%;
            }
            .page3EndTextRight{
                width: 100%;
                text-align: left;
                line-height: 150%;
            }
        }
    }

    .buyPhoneRoot{
        position: fixed;
        top: 0%;
        width: 100%;
        height: 100%;
        .buyPhoneText{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            visibility: hidden;
            .buyPhoneTitleRoot{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .buyPhoneTitle{
                    margin-left: 20px;
                    //width: 100%;
                    //font-size: 100px;
                    white-space: nowrap;
                    //color: rgba(0,255,0,1);
                }
            }
            .buyPhoneDes{
                color: rgba(255,255,255,1);
                white-space: nowrap;
            }
            .alphaLogo{
                //position: absolute;
                //top: 40px;
                //left: -40px;
                //transform: translate(-50%,-50%);
            }
        }
    }
    .phoneText1{
        position: absolute;
        right: 2%;
        top: 120%;
        transform: translate(0%, -50%);
        width: 60%;
        text-align: left;
        white-space: nowrap;
        visibility: hidden;
    }
    .phoneText2{
        position: fixed;
        left: 2%;
        top: 120%;
        transform: translate(0%, -50%);
        width: 60%;
        text-align: left;
        white-space: nowrap;
        visibility: hidden;
    }
    .phoneExhibitionAnimationRoot{
        .chipAnimation{
            position: fixed;
            left: 50%;
            top: 50%;
            //width: 100%;
            //height: auto;
            height: 100%;
            //min-width: 40vw;
            min-height: 600px;
            max-width: 100%;
            max-height: 100%;
            //object-fit: contain;
            object-fit: cover;
            translate: -50% -50%;
            visibility: hidden;
            //opacity: 0;
        }
        .phoneExhibitionAnimation{
            position: fixed;
            left: 50%;
            top: 50%;
            //width: 100%;
            //height: auto;
            height: 100%;
            //min-width: 40vw;
            min-height: 600px;
            max-width: 100%;
            max-height: 100%;
            //object-fit: contain;
            object-fit: cover;
            translate: -50% -50%;
            //opacity: 0.6;
        }
    }
    .airDropGift{
        position: fixed;
        left: 50%;
        top: 50%;
        //width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        translate: -50% -50%;
        visibility: hidden;
    }
    .airDropText{
        position: fixed;
        right: 5%;
        top: 120%;
        transform: translate(0%, -50%);
        width: 50%;
        text-align: left;
        white-space: nowrap;
        visibility: hidden;
    }
    .airDropSupportTeam{
        position: fixed;
        right: 5%;
        top: 120%;
        transform: translate(0%, -50%);
        width: 50%;
        visibility: hidden;
        .airDropSupportTeamTitle{
            text-align: left;
            font-size: 40px;
        }
        .airDropSupportTeamList{
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .item{
                margin-right: 20px;
            }
        }
    }
    .airDropPhone2{
        position: fixed;
        left: 50%;
        top: 140%;
        //width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        translate: -50% -50%;
        visibility: hidden;
    }
    .attr{
        position: fixed;
        bottom: 50%;
        transform: translate(0%, 50%);
        right: 10%;
        visibility: hidden;
    }
    .topBarRoot{
        width: 100%;
        height: 50px;
        position: fixed;
        //background-color: black;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 2000;
        cursor: pointer;
        //pointer-events: none;
        .socialIcon{
            margin-right: 20px;
        }
        .appButton{
            width: 110px;
            height: 30px;
            background-color: rgba(0,255,0,1);
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(0,0,0,1);
        }
    }
    .fixedSize43{
        position: fixed;
        top: 0%;
        left: 50%;
        transform: translate(-50%, 0%);
        height: 100%;
        min-width: 40vw;
        aspect-ratio: 1.3333;
        //background-color: rgba(255,0,0,0.1);
    }

    /*.access{
        position: fixed;
        width: 100%;
        font-size: 180px;
        top: 1%;
    }
    .sss{
        position: fixed;
        width: 1200px;
        top: 36%;
        left: 2%;
        .title{
            font-size: 100px;
            text-align: left;
        }
        .des{
            font-size: 50px;
            text-align: left;
        }
    }*/
}

@primary-color: #1DA57A;