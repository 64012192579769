.App {
    text-align: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  .ant-avatar.ant-avatar-square{
    border-radius: 0;
  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .confirmModalWrap .ant-modal-footer{
      display: none;
  }
  .confirmModalWrap .ant-modal-title{
    width: 100%;
    text-align: center;
  }
  .confirmModalWrap.ant-modal .ant-modal-close{
      top: 5px;
      inset-inline-end: 5px;
      display: none;
  }
  .confirmModalWrap.ant-modal .ant-modal-title {
    font-size: 20px;
    font-weight: 500;
    background-color: var(--black);
    color: var(--white);
    font-weight: bold;
  }
  .noticeModal.ant-modal .ant-modal-content{
    border: 1px solid var(--color-gray);
    background-color: var(--black);
  }
  
  .voteModal.ant-modal .ant-modal-content{
    background: rgba(0, 0, 0, .7);
    backdrop-filter: blur(3px);
  
  }
  .voteModal.ant-modal{
    top: 0;
  }
  .voteModalWrap.ant-modal-wrap{
    inset: auto;
    top: 85px;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
  }
  .voteModalWrap.liveTips.ant-modal-wrap{
    top: 130px;
  }
  
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  
  